import React from "react";
import Card from "../shared/components/UIElements/Card";
import Button from "../shared/components/FormElements/Button";
import "./Auth.css";

interface IAuthProps {
  hsh: Function;
  matchChecker: Function;
  incorrectPw: boolean;
}

const Auth = (props: IAuthProps) => {
  const [pw, setPw] = React.useState("");

  const loginHandler = (event: any) => {
    event.preventDefault();
    props.matchChecker(props.hsh(pw, 5));
  };

  const pwChangeHandler = (e: any) => {
    setPw(e.target.value);
  };

  return (
    <Card className="authentication">
      <h2>Login Required</h2>
      <hr />
      <form className="login-form" onSubmit={loginHandler}>
        <label className="pw-label" htmlFor="pw">
          Password
        </label>
        <input
          className="pw-input"
          id="pw"
          type="password"
          onChange={pwChangeHandler}
          value={pw}
        />
        {props.incorrectPw && (
          <p className="incorrect">Password is incorrect.</p>
        )}
        <Button type="submit">Login</Button>
      </form>
    </Card>
  );
};

export default Auth;

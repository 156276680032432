import React from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import Slider from "react-slick";

import L, { LatLngExpression, LeafletMouseEvent } from "leaflet";
import FilterIcon from "../assets/filter-line.svg";
import CheckedFilterIcon from "../assets/funnel-check.svg";
import RemoveFilterIcon from "../assets/filter-remove.svg";
import CloseIcon from "../assets/close.svg";
import MarkerIcon from "../assets/map-marker-flag.svg";
import ResetIcon from "../assets/reset-update.svg";
import MarkerClusterGroup from "react-leaflet-markercluster";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import request from "graphql-request";
import {
  Campaign,
  Image,
  RootObject,
  Store,
  Storefront,
} from "../shared/models/Storefronts.model";

import Modal from "../shared/components/UIElements/Modal";
import "react-leaflet-markercluster/dist/styles.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "leaflet/dist/leaflet.css";
import "./MapPage.css";
import { parseUrl } from "query-string";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

interface IMapPageProps {
  apikey: string;
}
interface IMapSubcomponentProps {
  onZoomed: Function;
}

interface IParsedQuery {
  query: {
    Year: Array<string> | string;
    Country: Array<string> | string;
    Brand: Array<string> | string;
    Partner: Array<string> | string;
    Campaign: Array<string> | string;
  };
}

const MapSubcomponent = (props: IMapSubcomponentProps): null => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      if (mapEvents.getZoom() !== 6) {
        props.onZoomed(true);
      } else {
        props.onZoomed(false);
      }
    },
  });

  return null;
};

const MapPage = (props: IMapPageProps) => {
  const [map, setMap] = React.useState<L.Map>();
  const [storefronts, setStorefronts] = React.useState<Array<Storefront>>([]);
  // const [filteredStorefronts, setFilteredStorefronts] = React.useState<
  //   Array<Storefront>
  // >([]);
  const [filteredStores, setFilteredStores] = React.useState<Array<Store>>([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [showStorefront, setShowStore] = React.useState(false);
  const [selectedStore, setSelectedStore] = React.useState<Store | null>(null);
  const [selectedStorefront, setSelectedStorefront] =
    React.useState<Storefront | null>(null);

  const [yearOptions, setYearOptions] = React.useState<Array<string>>([]);
  const [yearSelected, setYearSelected] = React.useState<Array<string>>([]);
  const [countryOptions, setCountryOptions] = React.useState<Array<string>>([]);
  const [countrySelected, setCountrySelected] = React.useState<Array<string>>(
    []
  );
  const [brandOptions, setBrandOptions] = React.useState<Array<string>>([]);
  const [brandSelected, setBrandSelected] = React.useState<Array<string>>([]);
  const [partnerOptions, setPartnerOptions] = React.useState<Array<string>>([]);
  const [partnerSelected, setPartnerSelected] = React.useState<Array<string>>(
    []
  );
  const [campaignOptions, setCampaignOptions] = React.useState<Array<string>>(
    []
  );
  const [campaignSelected, setCampaignSelected] = React.useState<Array<string>>(
    []
  );
  const [filteredCampaignOptions, setFilteredCampaignOptions] = React.useState<
    Array<Campaign>
  >([]);
  const [filteredCampaignSelected, setFilteredCampaignSelected] =
    React.useState<Campaign | null>(null);
  const [imageToShow, setImageToShow] = React.useState<Image>(null);

  const [parsedQuery, setParsedQuery] = React.useState<IParsedQuery | null>(
    null
  );

  const [zoomed, setZoomed] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetchStorefronts();
    setParsedQuery(
      parseUrl(window.location.href, {
        arrayFormat: "separator",
        arrayFormatSeparator: "|",
      }) as unknown as IParsedQuery
    );
  }, []);

  const fetchStorefronts = async () => {
    const storefrontData: RootObject = await request(
      "https://api-eu-central-1.graphcms.com/v2/" + props.apikey + "/master",
      `{
        storefronts {
          campaign {
            brand {
              name
            }
            name
            year
          }
          images {
            name
            url
          }
          store {
            country {
              name
            }
            location {
              latitude
              longitude
            }
            name
            partner {
              name
            }
            id
          }
          text {
      html
    }
        }
      }
      `
    );

    if (
      storefrontData &&
      storefrontData.storefronts &&
      storefrontData.storefronts.length > 0
    ) {
      setStorefronts(storefrontData.storefronts);
      filterStorefronts();
    }
  };

  React.useEffect(() => {
    updateFiltersFromParsedQuery();
  }, [parsedQuery]);

  React.useEffect(() => {
    configureFilters();
  }, [storefronts]);

  React.useEffect(() => {
    if (
      yearOptions.length > 0 ||
      countryOptions.length > 0 ||
      brandOptions.length > 0 ||
      partnerOptions.length > 0 ||
      campaignOptions.length > 0
    ) {
      setInitialSelectedFilters();
    }
  }, [
    yearOptions,
    countryOptions,
    brandOptions,
    partnerOptions,
    campaignOptions,
  ]);

  React.useEffect(() => {
    if (
      yearSelected.length !== 0 &&
      countrySelected.length !== 0 &&
      brandSelected.length !== 0 &&
      partnerSelected.length !== 0 &&
      campaignSelected.length !== 0 &&
      storefronts.length !== 0
    ) {
      setIsLoading(false);
    }
    filterStorefronts();
  }, [
    yearSelected,
    countrySelected,
    brandSelected,
    partnerSelected,
    campaignSelected,
  ]);

  const updateFiltersFromParsedQuery = () => {
    if (parsedQuery) {
      if (parsedQuery.query.Year && parsedQuery.query.Year !== "") {
        if (typeof parsedQuery.query.Year === "string") {
          if (yearOptions.includes(parsedQuery.query.Year)) {
            setYearSelected([parsedQuery.query.Year]);
          }
        } else {
          let selected: Array<string> = [];
          (parsedQuery.query.Year as Array<string>).forEach((e) => {
            if (yearOptions.includes(e)) {
              selected.push(e);
            }
          });
          if (selected.length > 0) {
            setYearSelected(selected);
          }
        }
      }

      if (parsedQuery.query.Country && parsedQuery.query.Country !== "") {
        if (typeof parsedQuery.query.Country === "string") {
          if (countryOptions.includes(parsedQuery.query.Country)) {
            setCountrySelected([parsedQuery.query.Country]);
          }
        } else {
          let selected: Array<string> = [];
          (parsedQuery.query.Country as Array<string>).forEach((e) => {
            if (countryOptions.includes(e)) {
              selected.push(e);
            }
          });
          if (selected.length > 0) {
            setCountrySelected(selected);
          }
        }
      }

      if (parsedQuery.query.Brand && parsedQuery.query.Brand !== "") {
        if (typeof parsedQuery.query.Brand === "string") {
          if (brandOptions.includes(parsedQuery.query.Brand)) {
            setBrandSelected([parsedQuery.query.Brand]);
          }
        } else {
          let selected: Array<string> = [];
          (parsedQuery.query.Brand as Array<string>).forEach((e) => {
            if (brandOptions.includes(e)) {
              selected.push(e);
            }
          });
          if (selected.length > 0) {
            setBrandSelected(selected);
          }
        }
      }

      if (parsedQuery.query.Partner && parsedQuery.query.Partner !== "") {
        if (typeof parsedQuery.query.Partner === "string") {
          if (partnerOptions.includes(parsedQuery.query.Partner)) {
            setPartnerSelected([parsedQuery.query.Partner]);
          }
        } else {
          let selected: Array<string> = [];
          (parsedQuery.query.Partner as Array<string>).forEach((e) => {
            if (partnerOptions.includes(e)) {
              selected.push(e);
            }
          });
          if (selected.length > 0) {
            setPartnerSelected(selected);
          }
        }
      }

      if (parsedQuery.query.Campaign && parsedQuery.query.Campaign !== "") {
        if (typeof parsedQuery.query.Campaign === "string") {
          if (campaignOptions.includes(parsedQuery.query.Campaign)) {
            setCampaignSelected([parsedQuery.query.Campaign]);
          }
        } else {
          let selected: Array<string> = [];
          (parsedQuery.query.Campaign as Array<string>).forEach((e) => {
            if (campaignOptions.includes(e)) {
              selected.push(e);
            }
          });
          if (selected.length > 0) {
            setCampaignSelected(selected);
          }
        }
      }
    }
  };

  const filterStorefronts = () => {
    if (storefronts.length > 0) {
      const filteredItems: Storefront[] = storefronts.filter((sf) => {
        return (
          (!sf.campaign.year ||
            yearSelected.length === 0 ||
            yearSelected.includes(sf.campaign.year)) &&
          (!sf.store.country ||
            countrySelected.length === 0 ||
            countrySelected.includes(sf.store.country.name)) &&
          (!sf.campaign.brand ||
            brandSelected.length === 0 ||
            brandSelected.includes(sf.campaign.brand.name)) &&
          (!sf.store.partner ||
            partnerSelected.length === 0 ||
            partnerSelected.includes(sf.store.partner.name)) &&
          (!sf.campaign ||
            campaignSelected.length === 0 ||
            campaignSelected.includes(sf.campaign.name))
        );
      });

      let fs: Array<Store> = [];
      filteredItems.forEach((e) => {
        if (
          fs.filter((s) => {
            return s.id === e.store.id;
          }).length === 0
        ) {
          fs.push(e.store);
        }
      });

      setFilteredStores(fs);

      // setFilteredStorefronts(filteredItems);
      if (filteredItems.length < storefronts.length) {
        setIsFiltered(true);
      } else {
        setIsFiltered(false);
      }
      if (!isLoading) {
        let queryText = [];
        if (yearSelected.length > 0 && yearSelected !== yearOptions) {
          if (queryText.length === 0) {
            queryText.push("?Year=" + yearSelected.join("|"));
          } else {
            queryText.push("Year=" + yearSelected.join("|"));
          }
        }

        if (countrySelected.length > 0 && countrySelected !== countryOptions) {
          if (queryText.length === 0) {
            queryText.push("?Country=" + countrySelected.join("|"));
          } else {
            queryText.push("Country=" + countrySelected.join("|"));
          }
        }
        if (brandSelected.length > 0 && brandSelected !== brandOptions) {
          if (queryText.length === 0) {
            queryText.push("?Brand=" + brandSelected.join("|"));
          } else {
            queryText.push("Brand=" + brandSelected.join("|"));
          }
        }
        if (
          campaignSelected.length > 0 &&
          campaignSelected !== campaignOptions
        ) {
          if (queryText.length === 0) {
            queryText.push("?Campaign=" + campaignSelected.join("|"));
          } else {
            queryText.push("Campaign=" + campaignSelected.join("|"));
          }
        }
        if (partnerSelected.length > 0 && partnerSelected !== partnerOptions) {
          if (queryText.length === 0) {
            queryText.push("?Partner=" + partnerSelected.join("|"));
          } else {
            queryText.push("Partner=" + partnerSelected.join("|"));
          }
        }
        window.history.pushState(
          "filtered",
          "Filtered Storefronts",
          queryText.join("&")
        );
      }
    }
  };

  const markerClickHandler = (e: LeafletMouseEvent, s: Store) => {
    map?.flyTo([s.location.latitude, s.location.longitude], 15, {
      animate: true,
      duration: 1,
    });

    const fsf = storefronts.filter((sf) => {
      return sf.store.id === s.id;
    });

    let fco: Array<Campaign> = [];

    fsf.forEach((sf) => {
      if (
        fco.filter((co) => {
          return co.name === sf.campaign.name && co.year === sf.campaign.year;
        }).length === 0 &&
        campaignSelected.includes(sf.campaign.name) &&
        brandSelected.includes(sf.campaign.brand.name) &&
        yearSelected.includes(sf.campaign.year)
      ) {
        fco.push(sf.campaign);
      }
    });

    if (fco.length > 0) {
      fco.sort((a, b) => {
        if (a.year > b.year) {
          return -1;
        }
        if (a.year < b.year) {
          return 1;
        }

        return 0;
      });
      setFilteredCampaignOptions(fco);
      setFilteredCampaignSelected(fco[0]);
      selectStorefront(fco[0], s);
    }

    setSelectedStore(s);
    setShowStore(true);
  };

  const mapCreationHandler = (map: L.Map) => {
    if (map) {
      setMap(map);
    }
  };

  const closeFiltersHandler = () => {
    setShowFilters(false);
  };

  const closeStorefrontHandler = () => {
    setFilteredCampaignOptions([]);
    setFilteredCampaignSelected(null);
    setShowStore(false);
  };

  const configureFilters = () => {
    setYearOptions(
      storefronts
        .map((sf) => sf.campaign.year)
        .filter((value, index, self) => {
          return self.indexOf(value) === index && value !== null;
        })
        .sort()
    );
    setCountryOptions(
      storefronts
        .map((sf) => {
          if (!sf.store.country) {
            return "";
          } else {
            return sf.store.country.name;
          }
        })
        .filter((value, index, self) => {
          return (
            self.indexOf(value) === index && value !== null && value !== ""
          );
        })
        .sort()
    );
    setBrandOptions(
      storefronts
        .map((sf) => {
          if (!sf.campaign.brand) {
            return "";
          } else {
            return sf.campaign.brand.name;
          }
        })
        .filter((value, index, self) => {
          return (
            self.indexOf(value) === index && value !== null && value !== ""
          );
        })
        .sort()
    );
    setPartnerOptions(
      storefronts
        .map((sf) => {
          if (!sf.store.partner) {
            return "";
          } else {
            return sf.store.partner.name;
          }
        })
        .filter((value, index, self) => {
          return (
            self.indexOf(value) === index && value !== null && value !== ""
          );
        })
        .sort()
    );
    setCampaignOptions(
      storefronts
        .map((sf) => {
          if (!sf.campaign) {
            return "";
          } else {
            return sf.campaign.name;
          }
        })
        .filter((value, index, self) => {
          return (
            self.indexOf(value) === index && value !== null && value !== ""
          );
        })
        .sort()
    );
  };

  const resetFiltersHandler = () => {
    setParsedQuery(null);
    window.history.pushState("filtersCleared", "All Storefronts", "/");
    // setFilteredStorefronts(storefronts);
    setInitialSelectedFilters();
    setIsFiltered(false);
  };

  const setInitialSelectedFilters = () => {
    if (yearOptions.length > 0) {
      setYearSelected(yearOptions);
    }
    if (countryOptions.length > 0) {
      setCountrySelected(countryOptions);
    }
    if (brandOptions.length > 0) {
      setBrandSelected(brandOptions);
    }
    if (partnerOptions.length > 0) {
      setPartnerSelected(partnerOptions);
    }
    if (campaignOptions.length > 0) {
      setCampaignSelected(campaignOptions);
    }
    if (parsedQuery) {
      updateFiltersFromParsedQuery();
    }
  };

  const filterHeader = (
    <div className="filter-header">
      Filters{" "}
      {isFiltered && (
        <img
          onClick={resetFiltersHandler}
          src={RemoveFilterIcon}
          alt="reset filters"
          title="Reset Filters"
          height="35"
        />
      )}
      <img
        onClick={closeFiltersHandler}
        src={CloseIcon}
        alt="Close"
        title="Close"
        height="35"
      />
    </div>
  );

  const selectStorefront = (c: Campaign, s: Store) => {
    const sf = storefronts.find((storefront) => {
      return (
        storefront.campaign.name === c.name && storefront.store.id === s?.id
      );
    });

    if (sf) {
      setSelectedStorefront(sf);
    }
  };

  const campaignSelectionHandler = (e: any) => {
    setFilteredCampaignSelected(e.value);
    selectStorefront(e.value, selectedStore);
  };

  const storeHeader = (
    <div className="storefront-header">
      <span className="storefront-title">{selectedStore?.name}</span>
      <Dropdown
        id="campaign-dropdown"
        value={filteredCampaignSelected}
        options={filteredCampaignOptions}
        optionLabel="name"
        onChange={campaignSelectionHandler}
      />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  const customMarker = L.icon({
    iconUrl: MarkerIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    className: "map-icon",
  });

  const resetMapPosition = () => {
    map?.flyTo([48.624593, 16.8214183], 6, {
      animate: true,
      duration: 1,
    });
  };

  const zoomHandler = (b: boolean) => {
    if (zoomed !== b) {
      setZoomed(b);
    }
  };

  return (
    <>
      <Modal
        show={showFilters}
        onCancel={closeFiltersHandler}
        header={filterHeader}
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
      >
        <div className="filter-content">
          <div className="filter-section year-section">
            <div className="filter-section__label">Year</div>
            <div className="filter-section__content">
              <div className="filter-control">
                <MultiSelect
                  id="year"
                  value={yearSelected}
                  options={yearOptions}
                  onChange={(e) => setYearSelected(e.value)}
                  display="chip"
                />
              </div>
            </div>
          </div>
          <div className="filter-section">
            <div className="filter-section__label">Country</div>
            <div className="filter-section__content">
              <div className="filter-control">
                <MultiSelect
                  id="country"
                  value={countrySelected}
                  options={countryOptions}
                  onChange={(e) => setCountrySelected(e.value)}
                  display="chip"
                />
              </div>
            </div>
          </div>
          <div className="filter-section">
            <div className="filter-section__label">Brand</div>
            <div className="filter-section__content">
              <div className="filter-control">
                <MultiSelect
                  id="brand"
                  value={brandSelected}
                  options={brandOptions}
                  onChange={(e) => setBrandSelected(e.value)}
                  display="chip"
                />
              </div>
            </div>
          </div>
          <div className="filter-section">
            <div className="filter-section__label">Partner</div>
            <div className="filter-section__content">
              <div className="filter-control">
                <MultiSelect
                  id="partner"
                  value={partnerSelected}
                  options={partnerOptions}
                  onChange={(e) => setPartnerSelected(e.value)}
                  display="chip"
                />
              </div>
            </div>
          </div>
          <div className="filter-section">
            <div className="filter-section__label">Campaign</div>
            <div className="filter-section__content">
              <div className="filter-control">
                <MultiSelect
                  id="campaign"
                  value={campaignSelected}
                  options={campaignOptions}
                  onChange={(e) => setCampaignSelected(e.value)}
                  display="chip"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showStorefront}
        onCancel={closeStorefrontHandler}
        header={storeHeader}
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
        // footer={}
      >
        <div className="storefront-content">
          <Slider {...settings}>
            {selectedStorefront?.images?.map((i) => {
              return (
                <div key={i.url}>
                  <img src={i.url} alt={i.name} className="slider-image" />
                  <div
                    className="magnify"
                    onClick={(e) => {
                      setImageToShow(i);
                    }}
                  >
                    Enlarge image
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="storefront-fields">
            {selectedStorefront?.campaign?.year && (
              <div className="storefront-field">
                <p className="storefront-field__label">Year</p>
                <p className="storefront-field__data">
                  {selectedStorefront?.campaign?.year}
                </p>
              </div>
            )}
            {selectedStorefront?.campaign?.brand?.name && (
              <div className="storefront-field">
                <p className="storefront-field__label">Brand</p>
                <p className="storefront-field__data">
                  {selectedStorefront?.campaign?.brand
                    ? selectedStorefront?.campaign?.brand.name
                    : ""}
                </p>
              </div>
            )}
            {selectedStore?.partner?.name && (
              <div className="storefront-field">
                <p className="storefront-field__label">Partner</p>
                <p className="storefront-field__data">
                  {selectedStore.partner.name}
                </p>
              </div>
            )}
            {selectedStorefront?.text?.html && (
              <div
                id="description"
                className="storefront-field"
                dangerouslySetInnerHTML={{
                  __html: selectedStorefront?.text?.html,
                }}
              ></div>
            )}
          </div>
          <div className="close">
            <img
              onClick={closeStorefrontHandler}
              src={CloseIcon}
              alt="Close"
              title="Close"
              height="30"
            />
          </div>
        </div>
      </Modal>
      <div className="root-container">
        <div className="filter-content-sidebar">
          <div className="filter-content-sidebar__contents"></div>
          <h2>
            Filters
            {isFiltered && (
              <img
                onClick={resetFiltersHandler}
                src={RemoveFilterIcon}
                alt="reset filters"
                title="Reset Filters"
                height="25"
              />
            )}
          </h2>
          {yearOptions.length > 0 && (
            <div className="filter-section year-section">
              <div className="filter-section__label">Year</div>
              <div className="filter-section__content">
                <div className="filter-control">
                  <MultiSelect
                    id="year"
                    value={yearSelected}
                    options={yearOptions}
                    onChange={(e) => setYearSelected(e.value)}
                    display="chip"
                  />
                </div>
              </div>
            </div>
          )}
          {countryOptions.length > 0 && (
            <div className="filter-section">
              <div className="filter-section__label">Country</div>
              <div className="filter-section__content">
                <div className="filter-control">
                  <MultiSelect
                    id="country"
                    value={countrySelected}
                    options={countryOptions}
                    onChange={(e) => setCountrySelected(e.value)}
                    display="chip"
                  />
                </div>
              </div>
            </div>
          )}
          {brandOptions.length > 0 && (
            <div className="filter-section">
              <div className="filter-section__label">Brand</div>
              <div className="filter-section__content">
                <div className="filter-control">
                  <MultiSelect
                    id="brand"
                    value={brandSelected}
                    options={brandOptions}
                    onChange={(e) => setBrandSelected(e.value)}
                    display="chip"
                  />
                </div>
              </div>
            </div>
          )}
          {partnerOptions.length > 0 && (
            <div className="filter-section">
              <div className="filter-section__label">Partner</div>
              <div className="filter-section__content">
                <div className="filter-control">
                  <MultiSelect
                    id="partner"
                    value={partnerSelected}
                    options={partnerOptions}
                    onChange={(e) => setPartnerSelected(e.value)}
                    display="chip"
                  />
                </div>
              </div>
            </div>
          )}
          {campaignOptions.length > 0 && (
            <div className="filter-section">
              <div className="filter-section__label">Campaign</div>
              <div className="filter-section__content">
                <div className="filter-control">
                  <MultiSelect
                    id="campaign"
                    value={campaignSelected}
                    options={campaignOptions}
                    onChange={(e) => setCampaignSelected(e.value)}
                    display="chip"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="main-container">
          <div className="top-container">
            <h1 className="page-title" onClick={resetMapPosition}>
              <span className="red">red</span>
              <span className="snow">snow</span>{" "}
              {zoomed && (
                <img
                  src={ResetIcon}
                  alt="Reset Zoom"
                  title="Reset Zoom"
                  height="30"
                />
              )}
            </h1>
            <div className="filter-container">
              <Button
                className="filter-button"
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                <img
                  src={isFiltered ? CheckedFilterIcon : FilterIcon}
                  alt="filter"
                  title="Filter"
                  height="30"
                />
              </Button>
            </div>
          </div>
          <div className="content-container">
            <MapContainer
              center={[48.624593, 16.8214183] as LatLngExpression}
              zoom={6}
              className="map-container"
              whenCreated={mapCreationHandler}
              minZoom={6}
              zoomControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>
                &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a>
                &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a>
                &copy; <a href="https://www.openstreetmap.org/about/" target="_blank">OpenStreetMap contributors</a>'
                url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png?api_key=0b5ca516-21b3-40aa-a56c-b760639223df"
              />

              <MarkerClusterGroup
                showCoverageOnHover={false}
                maxClusterRadius={20}
              >
                {filteredStores.map((s) => {
                  return (
                    <Marker
                      key={s.id}
                      icon={customMarker}
                      position={
                        [
                          s.location.latitude,
                          s.location.longitude,
                        ] as LatLngExpression
                      }
                      eventHandlers={{
                        click: (e) => {
                          markerClickHandler(e, s);
                        },
                      }}
                    />
                  );
                })}
              </MarkerClusterGroup>
              <MapSubcomponent onZoomed={zoomHandler} />
            </MapContainer>
          </div>
        </div>
      </div>
      {imageToShow && (
        <div
          className="fullscreen-image"
          onClick={() => {
            setImageToShow(null);
          }}
        >
          <img src={imageToShow.url} alt={imageToShow.name} />
        </div>
      )}
    </>
  );
};

export default MapPage;

import React from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "./App.css";
import { AuthContext } from "./shared/context/auth-context";
import Auth from "./pages/Auth";
import MapPage from "./pages/MapPage";
import request from "graphql-request";

const API_KEY = "cl0v7qkza15rb01xveajhgd2u";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);
  // const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isPwIncorrect, setIsPwIncorrect] = React.useState(false);
  const [configString, setConfigString] = React.useState(null);
  const [loadingConfig, setLoadingConfig] = React.useState(false);
  // const [loadingConfig, setLoadingConfig] = React.useState(true);

  React.useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const configData = await request(
      "https://api-eu-central-1.graphcms.com/v2/" + API_KEY + "/master",
      `{
        configs(first: 1) {
          pw
          active
        }
      }`
    );

    if (configData.configs[0] && configData.configs[0].active === false) {
      setIsLoggedIn(true);
    } else {
      setConfigString(configData.configs[0] && configData.configs[0].pw);
    }

    setLoadingConfig(false);
  };

  const hsh = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  const checkIfMatch = (h: number) => {
    if (configString && hsh(configString, 5) === h) {
      setIsLoggedIn(true);
    } else {
      setIsPwIncorrect(true);
    }
  };

  const login = React.useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const logout = React.useCallback(() => {
    setIsLoggedIn(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}
    >
      {loadingConfig && <p>Loading...</p>}
      {!loadingConfig &&
        (isLoggedIn ? (
          <MapPage apikey={API_KEY} />
        ) : (
          <Auth
            hsh={hsh}
            matchChecker={checkIfMatch}
            incorrectPw={isPwIncorrect}
          />
        ))}
    </AuthContext.Provider>
  );
};

export default App;
